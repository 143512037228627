<template>
  <div coach-tv-related-list>
    <h2>{{ $t('coaching.MC_TITLE_PLAYLIST') }}</h2>
    <div v-if="hasItems" class="list-wrap">
      <div v-for="(item, i) in items" :key="i" class="list-item" @click="$emit('click', item)">
        <div class="thumbnail-wrap">
          <CdnImg :src="item.thumbnailUrl" class="thumbnail" />
        </div>
        <div class="info-wrap">
          <span class="title">{{ item.title }}</span>
          <span class="date">{{ formatDate(item.contentCreatedDatetime) }}</span>
        </div>
      </div>
      <div v-if="hasNext" class="more">
        <div class="divider">
          <button @click="$emit('more')">
            <span>{{ $t('coaching.BA_qlist_more') }}</span>
          </button>
          <hr />
        </div>
      </div>
    </div>
    <div v-else class="no-list">
      <img src="/img/icon/coach-no-content-list.svg">
      <p class="coach-not-find-text">{{ $t('coaching.MC_NO_DATA_PLAYLIST') }}</p>
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'CoachTvRelatedList',
  components: { CdnImg },
  props: {
    contentList: {
      default: () => ({}),
    },
  },
  computed: {
    _contentList() {
      return /** @type {CoachTvContentList} */ this?.contentList;
    },
    items() {
      return this?._contentList?.items;
    },
    hasItems() {
      return !!this?.items?.length;
    },
    hasNext() {
      return this?._contentList?.hasNext;
    },
  },
  methods: {
    formatDate(date) {
      return this.$date(date, 'dot');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-related-list] {
  .list-wrap {
    .list-item {.flex; .mb(5); border: 1px solid #ebebf0; cursor: pointer;
      .thumbnail-wrap { .w(150); .h(85);
        .thumbnail {.w(150); .h(85);}
      }
      .info-wrap { .flex; flex-direction: column; .p(8); overflow: hidden;
        .title {.fs(16); .c(#000); .bold; .ellipsis(2);}
        .date {.fs(12); .c(#a4a3ae); .mt(auto);}
      }
    }
    .more { .mt(40);
      .divider { .rel;
        > button { .h(30); .abs; .p(0, 8); .bgc(#ebebf0); .c(#000); .br(12); border: none; .z(1); right: 50%; transform: translate(50%, -50%);}
        > hr { .w(100%); .h(2); .bgc(#ebebf0);}
      }
    }
  }
  .no-list { .flex; flex-direction: column; border: 1px solid #ebebf0; .m(20, 0); .bgc(#fff); .h(165px); align-items: center; .justify-center;
    > img {.w(36); .h(36); .mb(20);}
    > p { .fs(14); .c(#000); .bold;}
  }

  @media (@tp-up) {
    .list-wrap {
      .list-item {
        .thumbnail-wrap {.w(200); .h(115);
          .thumbnail {.w(200); .h(115);}
        }
        .info-wrap { .p(20);}
      }
    }
  }
}

</style>
