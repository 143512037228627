<template>
  <div coach-tv-related-coach-list>
    <h2 class="related-coach-list-text">{{ $t('coaching.MC_TITLE_ASSO_COACH') }}</h2>
    <div v-if="hasRelatedCoaches" class="list-item-wrap">
      <CoachListItem v-for="(coach, i) in _relatedCoaches" :key="i" :coach="coach" />
    </div>
    <div v-else class="coach-not-find">
      <img src="/img/icon/coach-not-find.svg">
      <p class="coach-not-find-text">{{ $t('coaching.MC_NO_DATA_ASSO_COACH') }}</p>
    </div>
  </div>
</template>

<script>
import CoachListItem from '@/views/components/coaching/coachList/CoachListItem.vue';

export default {
  name: 'CoachTvRelatedCoachList',
  props: { relatedCoaches: { type: Array, default: () => ([]) } },
  components: {
    CoachListItem,
  },
  computed: {
    _relatedCoaches() {
      return /** @type {Coach[]} */ this?.relatedCoaches;
    },
    hasRelatedCoaches() {
      return !!this?._relatedCoaches?.length;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-related-coach-list] {
  .related-coach-list-text {.fs(20);}
  .list-item-wrap {.pt(30); .max-h(420); overflow-y: auto;
    [coach-list-item] { .h(110); .bgc(#fff); .mb(10); .br(20);
      .coach-avatar-holder {
        .coach-conn-status {.hide;}
      }
      .coach-name-wrap {
        .coach-name {.c(#000);}
      }
      .info-right-holder {
        .tier-holder {
          .tier-img {.bgc(#ebebf0);}
          .official-emblem {.bgc(#ebebf0);}
        }
        .rating-review-holder {
          [point-icon] {
            .amount {.c(#8300ff)}
          }
        }
      }
    }
  }
  .coach-not-find {.flex; flex-direction: column; border: 1px solid #ebebf0; .m(20, 0); .bgc(#fff); .h(165px); align-items: center; .justify-center; .br(20);
    > img {.w(36); .h(36); .mb(20);}
    > p { .fs(14); .c(#000); .bold;}
  }
}
</style>
