<template>
  <div coach-tv-video>
    <iframe v-if="contentUrl" id="coach-tv-iframe" allowfullscreen="true" class="video" frameborder="0"></iframe>
    <div v-else class="video skeleton"></div>
    <div class="video-info">
      <div class="top-holder">
        <h2 class="title">{{ title }}</h2>
        <button class="share" @click="shareBtnHandler">
          <img src="/img/icon/btn_share.svg">
        </button>
      </div>
      <div class="bottom-holder">
        <span class="date">{{ _date }}</span>
        <span class="group-title">{{ groupTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { copyClipboard } from '@shared/utils/commonUtils';

export default {
  name: 'CoachTvVideo',
  props: {
    tvContent: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    contentUrl: 'setIframeSrc',
  },
  computed: {
    _tvContent() {
      return /** @type {CoachTvContent} */ this.tvContent;
    },
    contentUrl() {
      return this?._tvContent?.contentUrl;
    },
    title() {
      return this?._tvContent?.title;
    },
    groupTitle() {
      return this?._tvContent?.tvContentGroup?.title;
    },
    date() {
      return this?._tvContent?.contentCreatedDatetime;
    },
    _date() {
      return this.$date(this.date, 'dot');
    },
  },
  methods: {
    shareBtnHandler() {
      const url = process.env.VUE_APP_HOME_URL + this.$route.fullPath;
      copyClipboard(url);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD', { type: 'success' });
    },
    setIframeSrc() {
      const iframeEl = document?.getElementById('coach-tv-iframe');
      iframeEl?.contentWindow?.location?.replace(this.contentUrl);
    },
  },
  mounted() {
    this.setIframeSrc();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-video] {.w(100%);
  .video { .w(100%); .h(510); .block; border-top-left-radius: 20px; border-top-right-radius: 20px;
    &.skeleton { .bgc(#a4a3ae); }
  }
  .video-info { .flex; flex-direction: column; .h(140); .bgc(#ebebf0); .p(25); border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;
    .top-holder { .flex;
      .title { .fs(16); .ellipsis(2);}
      .share { .w(40); .h(40); .min-w(40); .bgc(#fff); .br(8); .ml(auto);
        > img {.o(0.4);
        }
        &:hover {
          > img {.o(1);}
        }
      }
    }
    .bottom-holder { .mt(auto);
      .date { .fs(12); .c(#a4a3ae);}
      .group-title { .fs(12); .c(#8300ff); .ml(20);}
    }
  }
  @media (@tp-up) {
    .video-info {
      .top-holder {
        .title {.fs(24);}
      }
      .bottom-holder {
        .date {.fs(14); }
        .group-title { .fs(14);}
      }
    }
  }
}
</style>
