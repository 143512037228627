<template>
  <div coach-tv-viewer>
    <div class="top-wrap">
      <button class="btn-prev" @click="prevBtnHandler">
        <img src="/img/icon/icon-arrow-down-dark.svg">
      </button>
    </div>
    <div class="content-wrap">
      <div class="coach-tv-content-wrap">
        <div class="video-wrap">
          <CoachTvVideo :tvContent="tvContent" />
        </div>
        <div class="related-coach-list-wrap">
          <CoachTvRelatedCoachList :relatedCoaches="relatedCoaches" @click="relatedCoachClickHandler" />
        </div>
        <div class="related-content-list-wrap">
          <CoachTvRelatedList :contentList="relatedContentList" @click="relatedContentClickHandler" @more="fetchMoreRelatedContentList" />
        </div>
      </div>
      <div class="right-side">
        <DiscordButton />
        <CoachList />
      </div>
    </div>
  </div>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';
import CoachTvVideo from '@/views/components/coaching/coachTv/CoachTvVideo.vue';
import CoachTvRelatedCoachList from '@/views/components/coaching/coachTv/CoachTvRelatedCoachList.vue';
import CoachTvRelatedList from '@/views/components/coaching/coachTv/CoachTvRelatedList.vue';

export default {
  name: 'CoachTvViewer',
  components: {
    DiscordButton, CoachList, CoachTvVideo, CoachTvRelatedCoachList, CoachTvRelatedList,
  },
  data: () => ({
    tvContent: {},
    relatedContentList: [],
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachTv.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachTv.title') },
        { vmid: 'description', content: this.$t('meta.coachTv.description') },
        { vmid: 'url', content: this.$t('meta.coachTv.url') },
        { vmid: 'keywords', content: this.$t('meta.coachTv.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  mixins: [cursorFetcher({
    relatedContentList: ({ cursor, route, services }) => {
      const tvContentId = route?.params?.tvContentId;
      const tvContentGroupId = route?.params?.tvContentGroupId;
      return services.coaching.getCoachTvRelatedContentList({ tvContentId, tvContentGroupId, cursor, order: 'contentCreatedDatetime asc' });
    },
  })],
  computed: {
    _tvContent() {
      return /** @type {CoachTvContent} */ this?.tvContent;
    },
    relatedCoaches() {
      return this._tvContent?.relatedCoaches;
    },
  },
  methods: {
    fetchMoreRelatedContentList() {
      this.fetchMore(this.relatedContentList);
    },
    prevBtnHandler() {
      this.$router.push({ name: 'CoachTvViewAll' });
    },
    relatedCoachClickHandler() {},
    relatedContentClickHandler(item) {
      const tvContentId = item?.tvContentId;
      const tvContentGroupId = item?.tvContentGroup?.tvContentGroupId;
      if (!tvContentId || !tvContentGroupId) return;
      this.$router.push({
        name: 'CoachTvViewer',
        params: {
          tvContentId,
          tvContentGroupId,
        },
      });
      window.scrollTo(0, 0);
    },
  },
  async asyncData({ route, services, mixinFetcher }) {
    const tvContentId = route?.params?.tvContentId;
    if (!tvContentId) return;
    const tvContent = await services.coaching.getCoachTvContent({ tvContentId });
    return { tvContent, ...await mixinFetcher.fetch({ services, route }) };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-viewer] { .bgc(#fff); .pb(40);
  .top-wrap { .h(156); .max-w(1260); .m(0, auto); .pt(80); .pl(20); .pr(20);
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
  }
  .content-wrap { .flex; .m(0, auto); .pl(20); .pr(20);
    .coach-tv-content-wrap {.w(100%);
      .video-wrap { .mb(80);}
      .related-coach-list-wrap { .p(25); .bgc(#ebebf0);}
      .related-content-list-wrap { .mt(50); .p(20);}
    }
    .right-side {.ml(auto); .hide;}
  }

  @media (@tp-up) {
    .top-wrap { .pl(58); .pr(58);}
    .content-wrap {.pl(58); .pr(58);}
  }

  @media (@ds-up) {
    .top-wrap { .pl(0); .pr(0);}
    .content-wrap { .max-w(1260); .pl(0); .pr(0);
      .coach-tv-content-wrap { .max-w(848); .w(calc(100% - 432px)); }
      .right-side {display: unset;}
    }
  }

}
</style>
